import {
  Bootcamp,
  BootcampBannerEntry,
  Category,
  LiveSession,
  LiveSessionStatus,
  Program,
} from "@/schemaTypes";
import { useQuery } from "@apollo/client";
import {
  GetLiveSessionsForCarrouselDocument,
  GetLiveSessionsForCarrouselQuery,
} from "./graphql/getLiveSessionsForCarrousel.generated";
import { noCacheHeaders } from "@/utils/headers";
import { GetProgramsDocument } from "@/pages/OnlinePrograms/graphql/getPrograms.generated";
import { useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { useUser } from "@/providers/useUser";

export const filterSessionsByCategory = (
  sessions: LiveSession[],
  categoryType: keyof Pick<Program, "subCategory" | "level" | "brand">,
  categoryName: string
) => {
  return sessions?.filter((session) => {
    const categoryToFilter = session?.[categoryType as keyof LiveSession];
    const canFilterByCategory =
      Array.isArray(categoryToFilter) &&
      categoryToFilter.every(
        (categories) =>
          categories && typeof categories === "object" && "name" in categories
      );

    if (canFilterByCategory) {
      return categoryToFilter.some(
        (categories) =>
          (categories as Category).name.toString().toLowerCase() ===
          categoryName.toLowerCase()
      );
    }

    return false;
  });
};

export const filterProgramsByCategory = (
  programs: Program[],
  categoryType: keyof Pick<Program, "subCategory" | "level" | "brand">,
  categoryName: string
) => {
  if (!programs) return [];

  return programs.filter((program) => {
    const categoryToFilter = program?.[categoryType];
    const canFilterByCategory =
      Array.isArray(categoryToFilter) &&
      categoryToFilter.every(
        (categories) =>
          categories && typeof categories === "object" && "name" in categories
      );

    if (canFilterByCategory) {
      return categoryToFilter.some(
        (categories) =>
          (categories as Category).name.toString().toLowerCase() ===
          categoryName.toLowerCase()
      );
    }

    return false;
  });
};

type FilterableEntity = LiveSession | Program | Bootcamp;

export const filterByCategory = <T extends FilterableEntity>(
  items: T[],
  categoryType: keyof Pick<Program, "subCategory" | "level" | "brand">,
  categoryName: string
): T[] => {
  if (!items) return [];

  return items.filter((item) => {
    const categoryToFilter = item?.[categoryType as keyof T];
    const canFilterByCategory =
      Array.isArray(categoryToFilter) &&
      categoryToFilter.every(
        (category) => typeof category === "object" && "name" in category
      );

    if (canFilterByCategory) {
      return categoryToFilter.some(
        (category: Category) =>
          category.name.toString().toLowerCase() === categoryName.toLowerCase()
      );
    }

    return false;
  });
};

export const filterBootcampsByCategory = (
  bootcamps: BootcampBannerEntry[],
  categoryType: keyof Pick<Program, "subCategory" | "level" | "brand">,
  categoryName: string
) => {
  return bootcamps.filter((bootcamp) => {
    const categoryToFilter = bootcamp.parent?.[categoryType];
    const canFilterByCategory =
      Array.isArray(categoryToFilter) &&
      categoryToFilter.every(
        (categories) =>
          categories && typeof categories === "object" && "name" in categories
      );

    if (canFilterByCategory) {
      return categoryToFilter.some(
        (categories) =>
          (categories as Category).name.toString().toLowerCase() ===
          categoryName.toLowerCase()
      );
    }

    return false;
  });
};

export const useQuerySessions = () => {
  const { geolocation } = useUser();
  const timezone = geolocation.location;

  const { data, loading, refetch } = useQuery(
    GetLiveSessionsForCarrouselDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  const filterValidSessions = useCallback(
    (
      sessions: GetLiveSessionsForCarrouselQuery["getLiveSessionsForCarrousel"]["liveSessions"]
    ) => {
      if (!sessions) return [];

      const now = DateTime.now().setZone(timezone);
      return sessions.filter(
        (session): session is NonNullable<(typeof sessions)[number]> => {
          if (
            !session ||
            session.status === LiveSessionStatus.Ended ||
            !session.endDate
          ) {
            return false;
          }

          const sessionEnd = DateTime.fromISO(session.endDate.toString(), {
            zone: timezone,
          });
          const hideTime = sessionEnd.minus({ minutes: 15 });
          return now < hideTime;
        }
      );
    },
    [timezone]
  );

  const validLiveSessions = useMemo(
    () => filterValidSessions(data?.getLiveSessionsForCarrousel.liveSessions),
    [data?.getLiveSessionsForCarrousel.liveSessions, filterValidSessions]
  );

  const validScheduledSessions = useMemo(
    () =>
      filterValidSessions(data?.getLiveSessionsForCarrousel.scheduledSessions),
    [data?.getLiveSessionsForCarrousel.scheduledSessions, filterValidSessions]
  );

  return {
    sessions: validLiveSessions,
    scheduledSessions: validScheduledSessions,
    loading,
    refetch,
  };
};

export const useQueryProgramsData = () => {
  const { data: rawPrograms, loading } = useQuery(GetProgramsDocument, {
    variables: {
      courseType: "ADVANCED_COURSE",
    },
  });

  const parsedCourses = rawPrograms?.courses.map((course) => {
    return {
      ...course,
      programType: course.courseType,
    };
  });

  const parsedPrograms = rawPrograms?.searchAllPrograms.filter(
    (program) => program.status === "PUBLISHED"
  );

  return {
    allPrograms: [...(parsedPrograms || []), ...(parsedCourses || [])].sort(
      (a, b) => (a?.order ?? 0) - (b?.order ?? 0)
    ),
    getProgramsLoading: loading,
  };
};
